import React, { useState, useCallback } from "react";
import { Button, Collapse, message, Modal, Spin, Typography } from "antd";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import ObjectDisplayer from "../../../components/ObjectEditor/ObjectEditor";
import FileViewer from "../../../components/FileViewer/FileViewer";
import Important from "../../../components/Important/Important";
const { Title, Text } = Typography;

const FileExtraction = ({ updateUser }) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFile(file);
    const fileType = file.type.includes("pdf") ? "pdf" : "image";
    setFileType(fileType);
  }, []);

  const handleUpdateUser = useCallback(
    async (response) => {
      if (typeof response.data == "object") {
        Modal.confirm({
          title: "Review data and merge",
          width: "100%",
          content: (
            <>
              <h3>Please review this data and hit ok to upload.</h3>
              <ObjectDisplayer obj={response?.data ?? {}} />
            </>
          ),
          onOk: async () => {
            await updateUser({ ...response.data, liheap_uploaded: [true, "Yes", "yes", "YES"].includes(response.data.is_liheap) ? true : false });
          },
        });
      } else {
        Modal.error({
          title: "Error Occurred!",
          content: (
            <div style={{ fontSize: "16px", lineHeight: "1.5" }}>
              <p>Please use another image/PDF, as this one resulted in an error. Click below to see details:</p>
              <Collapse>
                <Collapse.Panel header="Error Details" key="1">
                  <pre style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "4px", maxHeight: "200px", overflowY: "auto" }}>{JSON.stringify(response.data ?? {}, null, 2)}</pre>
                </Collapse.Panel>
              </Collapse>
              <p>Please note that you cannot use ID cards for generic extraction! There is a separate tab for ID card extraction.</p>
            </div>
          ),
        });
      }
    },
    [updateUser]
  );

  const handleUpload = useCallback(async () => {
    if (!file) {
      message.error("Please select a file first.");
      return;
    }

    setLoading(true);

    try {
      let response;
      if (fileType === "pdf") {
        const formData = new FormData();
        formData.append("pdf", file);

        response = await axios.post("http://alistermartin.pythonanywhere.com/generic_extraction", formData);
        console.log(response.data);
        setLoading(false);
        await handleUpdateUser(response);
      } else {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const base64Image = reader.result;
          const payload = {
            image: base64Image,
          };

          response = await axios.post("http://alistermartin.pythonanywhere.com/generic_extraction", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          await handleUpdateUser(response);
          setLoading(false);
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      message.error("File upload failed!");
      console.error("API Error:", error);
      setLoading(false);
    }
  }, [file, fileType, handleUpdateUser]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".pdf, image/*",
  });

  return (
    <Spin spinning={loading} tip={"Running Extraction...."}>
      <div style={{ padding: "20px", maxWidth: "400px", margin: "auto", textAlign: "center" }}>
        <Title level={2}>File Upload for Extraction</Title>
        <Text>Upload a file to extract details such as name, address, email, etc. </Text>
        <div
          {...getRootProps()}
          style={{
            border: "2px dashed #1890ff",
            borderRadius: "5px",
            padding: "20px",
            marginTop: "20px",
            cursor: "pointer",
            backgroundColor: isDragActive ? "#e6f7ff" : "#fafafa",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? <p>Drop the files here ...</p> : <p>Drag & drop a file here, or click to select a file</p>}
        </div>
        {file && <p style={{ marginTop: "10px" }}>{file.name}</p>}
        <Important active={file}>
          <Button size={"large"} type="primary" onClick={handleUpload} style={{ marginTop: "20px" }} disabled={!file}>
            Run Extraction...
          </Button>
        </Important>
        {file && <FileViewer file={file} fileType={fileType} />}
      </div>
    </Spin>
  );
};

export default FileExtraction;
