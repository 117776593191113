import React, { useState, useCallback, useContext } from "react";
import { Button, Space, Tabs, Typography, Alert, message } from "antd";
import { CameraOutlined } from "@ant-design/icons";
import Webcam from "react-webcam"; // Make sure to import the Webcam component
import IdCardData, { make } from "./comp/IdCardData";
import { catcher } from "../../../firebase/catcher";
import useSize from "../../../components/use/useSize";
import { convertListToObject } from "../../../helpers/obj";
import ReactDropZoneMe from "../../../components/ReactDropZoneMe";
import MainContext from "../../../contexts/mainContext";

const { TabPane } = Tabs;

const EXTRACT_ENDPOINT_URL =
  "http://alistermartin.pythonanywhere.com/id-extract";

// WARNING: Please make sure we are discarding the user data after scanning.
export default function IdCardExtractor({ handleUpdateUser }) {
  const webcamRef = React.useRef(null);
  const config = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("capture");
  const [extractedData, setExtractedData] = useState([]);
  const [capturedImage, setCapturedImage] = useState("");
  const [isFrontFacing, setIsFrontFacing] = useState(true);
  const { isSmall, isExtraSmall } = useSize();

  const captureUsingCamera = useCallback(async () => {
    try {
      if (!webcamRef.current) return;

      const imageSrc = webcamRef.current.getScreenshot();
      const base64Image = imageSrc?.split(",")[1];
      await extractIdCard(base64Image);
      setCapturedImage(imageSrc);
      setActiveTab("done");
    } catch (err) {
      console.log(err);
      message.info("please retry");
    }
  }, [webcamRef]);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64 = reader.result?.split(",")[1];
      await extractIdCard(base64);
      setCapturedImage(reader.result);
      setActiveTab("done");
    };
    reader.readAsDataURL(file);
  };

  const extractIdCard = async (base64Image) => {
    catcher(
      async () => {
        const response = await fetch(EXTRACT_ENDPOINT_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: base64Image }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("MAKING DATA", make(data), data);
        setExtractedData(make(data));
      },
      { setLoading }
    );
  };

  const handleSubmit = async () => {
    // Handle submission logic here
    const data = convertListToObject(extractedData);
    if (config?.loc === "tx") {
      handleUpdateUser(data, "external_script");
    } else {
      handleUpdateUser(data);
    }
  };

  const videoConstraints = {
    facingMode: isFrontFacing ? "user" : "environment",
  };

  const toggleFacingMode = () => {
    setIsFrontFacing((prev) => !prev);
  };

  return (
    <>
      {/* <p>
        Instructions: 1. Capture/Upload image of an id card. 2. Confirm/Change
        the data. 3. Hit submit.
      </p> */}
      {activeTab !== "done" && (
        <>
          <Alert
            description={
              "Use this feature to quickly extract basic information from any ID. Please double-check the data."
            }
          />

          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab="Capture" key="capture">
              <div
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                {(isSmall || isExtraSmall) && (
                  <button onClick={toggleFacingMode} style={{ margin: 10 }}>
                    {isFrontFacing
                      ? "Switch to Back Camera"
                      : "Switch to Front Camera"}
                  </button>
                )}
                <div style={{ position: "relative" }}>
                  <p>click anywhere to capture</p>
                  <Webcam
                    onClick={captureUsingCamera}
                    audio={false}
                    ref={webcamRef}
                    videoConstraints={videoConstraints}
                    screenshotFormat="image/png"
                    style={{
                      maxWidth: "100%",
                      borderRadius: "4px",
                      border: "1px solid rebeccapurple",
                      marginBottom: 20,
                    }}
                  />
                  <Button
                    style={{
                      position: "absolute",
                      bottom: "0%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      border: "1px solid black",
                      // backgroundColor: "rgba(255, 255, 255, 0.5)", // Faded button
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                    onClick={captureUsingCamera}
                    icon={<CameraOutlined />}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane tab="Upload" key="upload">
              <ReactDropZoneMe handleImageUpload={handleImageUpload} />
            </TabPane>
          </Tabs>
        </>
      )}
      {activeTab === "done" && (
        <div className="content">
          {capturedImage && (
            <>
              <Typography.Title level={4}>Image Captured</Typography.Title>
              <img
                src={capturedImage}
                alt="Captured/Uploaded"
                style={{ maxWidth: "100%", border: "2px solid rebeccapurple" }}
              />
            </>
          )}
          <div>
            <Typography.Title level={4}>Extracted data</Typography.Title>
            <Alert
              type="warning"
              showIcon
              message={
                "Please double-click the label/value to edit any incorrect details."
              }
            />
            <IdCardData
              data={extractedData}
              loading={loading}
              handleEdit={setExtractedData}
            />
          </div>
          <Space>
            <Button onClick={() => setActiveTab("capture")}>Reupload</Button>
            <Button type="primary" onClick={handleSubmit} loading={loading}>
              Submit {config?.loc === "tx" && <>& fill texas application</>}
            </Button>
          </Space>
        </div>
      )}
    </>
  );
}
