import { FilePdfOutlined, UploadOutlined } from "@ant-design/icons";
import { Modal, Tabs, Upload, message } from "antd";
import React, { useState } from "react";
import IdCardExtractor from "./IdCardExtraction";
import { ExternalScriptsPage } from "../../ExternalSite";
import useSize from "../../../components/use/useSize";
import GenericExtraction from "./GenericExtraction";
import TabsComponent from "./comp/TabsComponent";

const { TabPane } = Tabs;

const MultiInputModal = ({ id, open, setOpen, user, updateUser }) => {
  const [activeTab, setActiveTab] = useState("external_script");
  const { isSmall, isExtraSmall } = useSize();

  const handleUpload = (info) => {
    const { status } = info.file;
    if (status === "done") {
      message.success(`${info.file.name} uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} upload failed.`);
    }
  };
  const handleUpdateUser = async (newData, goto) => {
    await updateUser(newData);
    if (goto) {
      setActiveTab(goto);
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const propsChildCare = {
    name: "file",
    multiple: false,
    style: { width: "100%" },
    accept: ".pdf",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange: handleUpload,
  };

  const propsMsp = {
    name: "file",
    multiple: false,
    accept: ".pdf",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange: handleUpload,
  };

  const propsGeneric = {
    name: "file",
    multiple: false,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange: handleUpload,
    beforeUpload(file) {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        message.error("You can only upload PDF files!");
      }
      return isPdf;
    },
  };

  const items = [
    
    {
      key: "external_script",
      label: "Fill Applications",
      content: (
        <div>
          <ExternalScriptsPage id={id} updateUser={updateUser} user={user} />
        </div>
      ),
    },
    {
      key: "scan_cards",
      label: "Scan Cards",
      content: <IdCardExtractor handleUpdateUser={handleUpdateUser} />,
    },

    // {
    //   key: "2",
    //   label: "Child Care Upload",
    //   content: (
    //     <>
    //       <Upload.Dragger
    //         {...propsChildCare}
    //         style={{ width: "100%", maxWidth: "400px" }}
    //       >
    //         <p className="ant-upload-drag-icon">
    //           <FilePdfOutlined />
    //         </p>
    //         <p className="ant-upload-text">
    //           Click or drag PDF file to this area to upload
    //         </p>
    //         <p className="ant-upload-hint">Supports only PDF files.</p>
    //       </Upload.Dragger>
    //       <p>Instructions: Upload the Child Care document in PDF format.</p>
    //     </>
    //   ),
    // },
    // {
    //   key: "3",
    //   label: "MSP Upload",
    //   content: (
    //     <>
    //       <Upload.Dragger
    //         {...propsMsp}
    //         style={{ width: "100%", maxWidth: "400px" }}
    //       >
    //         <p className="ant-upload-drag-icon">
    //           <FilePdfOutlined />
    //         </p>
    //         <p className="ant-upload-text">
    //           Click or drag PDF file to this area to upload
    //         </p>
    //         <p className="ant-upload-hint">Supports only PDF files.</p>
    //       </Upload.Dragger>
    //       <p>Instructions: Upload the MSP document in PDF format.</p>
    //     </>
    //   ),
    // },
    {
      key: "4",
      label: "Generic Extraction",
      content: <GenericExtraction updateUser={updateUser} />,
    },
  ];
  const tabMobileOptions = !isSmall && !isExtraSmall ? { style: { minHeight: "70vh" }, tabPosition: "left" } : {};
  return (
    <Modal open={open} title="Upload Documentation/ Fill Forms" onCancel={() => setOpen(false)} footer={null} width={"100%"} bodyStyle={{ minHeight: "70vh", padding: 0 }}>
      <TabsComponent tabMobileOptions={tabMobileOptions} setActiveTab={setActiveTab} activeTab={activeTab} items={items} />
    </Modal>
  );
};

export default MultiInputModal;
