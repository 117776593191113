import { Alert, Button, Divider, Input, Modal, Row, Space, Tooltip, Typography, message, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { PiCheckDuotone, PiNewspaperDuotone } from "react-icons/pi";
import Loading from "../../components/Loading/Loading";
import { catcher } from "../../firebase/catcher";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import Instructions from "../Instructions";
import useSize from "../../components/use/useSize";
import MainContext, { mainContextConfig } from "../../contexts/mainContext";
import { configurateKinderWaitData, mapDataToKinderWait } from "./kinderwait.config";
import axios from "axios";
import { formatObjectIntoStr } from "../../helpers/text";
import { getCurrentDate } from "../../helpers/time";
import { TiArrowRightOutline, TiTickOutline } from "react-icons/ti";
import { mapDataToTexasLifeline } from "./texaslifeline.config";
import { FaCheck, FaInternetExplorer, FaRegFilePdf } from "react-icons/fa6";
import { BiGlobe } from "react-icons/bi";
import { configurateChampForm } from "./champform.config";
import { configurateMedicareSavingsData, mapDataToMedicare } from "./medicaresavings.config";
import { cleanObject, flattenJson } from "../../helpers/obj";
import { configurateDTAConnect, mapDataToDTA } from "./dta.config";
import { mapDataToWic } from "./wic.config";
import { mapDataToGetYourRefund } from "./getyourrefund.config";
import { mapDataToBabySteps } from "./babysteps.config";
import { appendQueryParams } from "../../helpers/loc";
import { mapDataToWicTexas } from "./wictexas.config";

const downloadApplication = async (downloadPathTemplate, id, user, key) => {
  try {
    const url = `http://alistermartin.pythonanywhere.com/get_file/${downloadPathTemplate.replace("{id}", id)}`;
    const response = await axios.get(url, { responseType: "blob" });

    const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = urlBlob;
    link.setAttribute("download", "application.pdf");
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    message.error("Error downloading the application");
  }
};

const tamper_monkey_should_be_version = 'new1'; //change this version after changing the version in the userscript
const config = {
  wic: {
    title: "WIC - Women, Infants and Children Program (Online Form)",
    url: "https://www.mass.gov/forms/apply-for-wic-online",
    desc: "Apply for the WIC program online to receive nutritional support for women, infants, and children.",
    filter: ["ma"],
    cannot_be_iframed: true,
    dataString: (data) => {
      return mapDataToWic(data);
    },
    download_path_template: "wic_form/{id}.pdf",
    uploaded_key: "mass_gov_wic_uploaded",
    // wic_questions:["name",""]
  },
  texas_lifeline: {
    title: "Texas Lifeline",
    url: "https://www.texaslifeline.org/createorder/",
    filter: ["tx"],
    uploaded_key: "texas_uploaded",
    cannot_be_iframed: true,

    desc: "The Lifeline program is designed to help qualified low-income individuals pay the monthly cost of basic telephone service. A resident is qualified if the current total household income is at or below 150 percent of the federal poverty guidelines.",
  },
  dta_connect: {
    title: "TAFDC Program (DTA Connect Website)",
    url: "https://dtaconnect.eohhs.mass.gov/applyTafdc",
    desc: "Apply for the TAFDC Program through the DTA Connect website to receive transitional aid and support.",
    filter: ["ma"],
    uploaded_key: "dta_uploaded",
    cannot_be_iframed: true,
    dataString: (data) => {
      return mapDataToDTA(data);
    },
  },
  kinderwait_intake: {
    title: "Child Care Assistance (Kinder Wait Intake Form)",
    uploaded_key: "kinderwait_uploaded",
    url: "https://chotey.com/kinderwaitintakeform/form.html",
    desc: "Complete the Kinder Wait Intake form to apply for child care assistance and support services.",
    filter: ["ma"],
    download_path_template: "kinder_wait/{id}.pdf",
    dataString: (data) => {
      return mapDataToKinderWait(data);
    },
    type: "pdf",
    desc_alert: "Please double check the application before submitting, as it triggers an email. Saving multiple times would trigger multiple emails.",
  },
  medicare_savings: {
    title: "Medicare Savings Program (MSP) - (Form)",
    url: "https://chotey.com/medicare savings/form.html",
    desc: "Fill out the Medicare Savings Program form to apply for assistance with Medicare costs.",
    filter: ["ma"],
    uploaded_key: "medicare_uploaded",
    dataString: (data) => {
      return mapDataToMedicare(data);
    },
    download_path_template: "medicare/{id}.pdf",
    type: "pdf",
    require_signature: true,
    desc_alert: "Please double check the application before submitting, as it triggers an email. Saving multiple times would trigger multiple emails.",
  },
  getyourrefund: {
    title: "Tax Refund - Getyourrefund.com",
    url: "https://www.getyourrefund.org/en/questions/triage-personal-info",
    desc: "Fill this application to get upto 1500$ tax refund.",
    filter: ["ma", "tx"],
    uploaded_key: "getyourrefund_started",
    cannot_be_iframed: true,
    dataString: (data) => {
      return mapDataToGetYourRefund(data);
    },
  },
  babysteps: {
    title: "Baby Steps (mass.gov fidelity website)",
    url: "https://digital.fidelity.com/prgw/digital/aox/CollegeSavingsPlanAccountOpening/PersonalInformation?OFFER_CD=&dbs=",
    desc: "Receive a $50 deposit into a U.Fund 529 College Investing Plan ",
    filter: ["ma"],
    cannot_be_iframed: true,
    uploaded_key: "babysteps_started",
    // cannot_be_iframed: true,
    dataString: (data) => {
      return mapDataToBabySteps(data);
    },
  },
  wictexas: {
    title: "WIC Texas",
    url: "https://txwic.gov1.qualtrics.com/jfe/form/SV_8B46S0pZv1Qi2CW?Q_Language=EN&source=website-text-link",
    filter: ["tx"],
    uploaded_key: "wictexas_uploaded",
    desc: "WIC application for Texas",
    cannot_be_iframed: true,
    dataString: (data) => {
      return mapDataToWicTexas(data);
    },
  },
};

const texasSync = (data) => {
  const setField = (key, value) => {
    try {
      if (typeof value === "function") {
        value = value();
      }
      console.log(key, value, value && value !== "" && (!data[key]?.value || data[key]?.value === ""));
      if (value && value !== "" && (!data[key]?.value || data[key]?.value === "")) {
        console.log(key, value);
        data[key] = {
          key,
          value: typeof value === "object" ? value.value : value,
        };
      }
    } catch (error) {
      console.error(`Error setting field ${key}:`, error);
    }
  };

  console.log("Before sync:", JSON.parse(JSON.stringify(data)));

  const fields = {
    name: () => `${data.applicantFirstName.value} ${data.applicantLastName.value}`,
    ssn_last4: data.applicantSsn.value,
    phone_number: data.applicantPhoneNumber.value,
    phone: data.applicantPhoneNumber.value,
    email: data.applicantEmail.value,
    state: data.serviceAddressState.value,
    zip: data.mailingAddressZipCode.value,
    texas_uploaded: true,
    city: data.mailingAddressCity.value,
    address: () => `${data.mailingAddressStreet1.value} ${data.mailingAddressCity.value}`,
  };

  for (const [key, value] of Object.entries(fields)) {
    setField(key, value);
  }

  console.log("After sync:", JSON.parse(JSON.stringify(data)));
  return data;
};

export function ExternalScriptsPage({ id, form, updateUser, user }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentScript, setCurrentScript] = useState(null);
  const { isMobile } = useSize();
  const [ins, setIns] = useState(false); // ins modal
  const navigate = useNavigate();
  const [filters, setFilters] = useState({ matchStr: "" });
  const { loc } = useContext(MainContext);
  const locConfig = mainContextConfig["loc"][loc];
  const showModal = (script) => {
    setCurrentScript(script);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentScript(null);
  };

  // Memoize the extension check to prevent unnecessary re-renders
  const isExtensionWorking = React.useCallback((showOnNotAvailable = true) => {
    const element = document.getElementById("ahdahdahd");

    if (!element) {
      if (showOnNotAvailable) showInstructions("userscript_install");
      return false;
    }

    try {
      const version = JSON.parse(element.innerText)?.VERSION;
      if (!version || version !== tamper_monkey_should_be_version) {
        if (showOnNotAvailable) showInstructions("userscript_update");
        return false;
      }
      return true;
    } catch (error) {
      console.error("Error checking extension version:", error);
      return false;
    }
  }, []); // Empty dependency array since this doesn't depend on any props/state

  const showInstructions = (of = "userscript_install") => {
    setIns(of);
  };

  const hideInstructions = () => {
    setIns(false);
  };

  return (
    <div style={{ padding: "16px" }}>
      <div className="flex1 space">
        <Typography.Title level={3}>Fill user applications.</Typography.Title>
        <Input.Search
          style={{ width: "200px" }}
          onChange={(e) => {
            setFilters({ ...filters, matchStr: e.target.value });
          }}
          title="Search"
        />
      </div>
      <a onClick={() => showInstructions("userscript_install")} style={{ cursor: "pointer", padding: 10 }}>
        <i>How to install/ update user script?</i>
      </a>

      {isExtensionWorking(false) && (
        <Alert
          style={{ background: "white" }}
          message={"User Script installed"}
          description={"User script is working properly. You can start filling these applications. If you find an issue, please try reinstalling tampermonkey."}
          type="success"
          showIcon
        />
      )}

      {!isExtensionWorking(false) && (
        <Alert message="Please install the user script in order to start filling applications." type="info" showIcon action={<Button onClick={showInstructions}>Instructions </Button>} />
      )}
      <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
        {Object.entries(config).map(([key, { title, url, desc, filter, download_path_template, desc_alert, type, uploaded_key }]) => (
          <div
            style={{
              display: filter.includes(loc) && JSON.stringify({ title, url, desc }).toLowerCase().includes(filters.matchStr.toLowerCase()) ? "flex" : "none",
              alignItems: "center",
            }}
            className="externalwebsitebox"
            key={key}
          >
            {!isMobile && <div>{type == "pdf" ? <FaRegFilePdf color="#1890ff" style={{ fontSize: "40px" }} /> : <BiGlobe color="#1890ff" style={{ fontSize: "40px" }} />}</div>}
            <div
              style={{ marginLeft: 10 }}
              className={user[uploaded_key] && "is_uploaded"}
              onClick={() => {
                if (isExtensionWorking()) showModal({ title, url, key });
              }}
            >
              <b
                level={4}
                style={{
                  color: "#1890ff",
                  textDecoration: "underline",
                  fontSize: 20,
                }}
              >
                {user[uploaded_key] && (
                  <Tooltip title={`Submission has been recieved for this application.`}>
                    <div style={{ color: "darkgreen" }}>
                      <FaCheck /> SUBMISSION RECIEVED
                    </div>
                  </Tooltip>
                )}
                {title}
              </b>
              <div className="item-description">{desc}</div>
              <div className="item-links">
                {user[key + "_updated"] && download_path_template && (
                  <>
                    <Divider />
                    <b>Upload history</b>
                    <br />
                    <Button
                      type="primary"
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        downloadApplication(download_path_template, id, user, key);
                      }}
                    >
                      Download Application (submitted on:
                      {user[key + "_updated_time"]?.value ?? user[key + "_updated_time"]})
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </Row>

      {currentScript && (
        <Modal
          footer={null}
          title="External Application"
          open={isModalVisible}
          onCancel={handleCancel}
          onOk={handleCancel}
          width="100%"
          style={{ top: 0 }}
          bodyStyle={{ minHeight: "95vh", padding: 0 }}
        >
          <div style={{ padding: "16px" }}>
            <ExternalScript id={id} form={currentScript.key} updateUser={updateUser} user={user} />
          </div>
        </Modal>
      )}

      <Modal
        title="User Script"
        open={ins}
        onCancel={hideInstructions}
        onOk={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        width={isMobile ? "100%" : "70%"}
        style={{ top: 0 }}
        bodyStyle={{ minHeight: "95vh", padding: 0 }}
      >
        <Instructions of={ins} />
      </Modal>
    </div>
  );
}

export default function ExternalScript({ id, form, updateUser, user }) {
  const [isLoading, setIsLoading] = useState(true);
  const { loc } = useContext(MainContext);

  const handleLoad = () => {
    setIsLoading(false);
  };
  console.log(user);
  useEffect(() => {
    const sendMessageToIframe = () => {
      const iframe = document.getElementById("urud");
      if (iframe) {
        const postMessage = { id: id, sent_by_ahd: true };
        console.log("POSTING MESSAGE", postMessage);
        iframe.contentWindow.postMessage(postMessage, "*");

        const intervalId = setInterval(() => {
          if (iframe?.contentWindow && form === "texas_lifeline") {
            iframe?.contentWindow?.postMessage({ set_data_4rm_ahd: mapDataToTexasLifeline(user) }, "*");
          }
        }, 1000);

        // Clear interval on cleanup
        return () => clearInterval(intervalId);
      }
    };

    if (!isLoading) {
      const timeoutId = setTimeout(() => {
        sendMessageToIframe();
      }, 1000);

      // Clear timeout on cleanup
      return () => clearTimeout(timeoutId);
    }
  }, [isLoading]);

  useEffect(() => {
    const handleMessage = (event) => {
      catcher(async () => {
        const data = event.data;
        if (data.sent_by_ext) {
          notification.open({
            message: "Data Synced",
            type: "success",
            description: `Data synced from external site ${form}`,
          });
          let user_data = data.data;
          console.log("Data Recieved", data);
          if (form === "texas_lifeline") {
            user_data = texasSync({ ...user, ...user_data });
          } else if (form === "dta_connect") {
            user_data = configurateDTAConnect(user_data);
          }

          //---- pdf forms

          if (form === "kinderwait_intake" || form === "champ" || form === "medicare_savings") {
            const pdfbase64 = data?.pdfBase64;
            user_data = await processPdfApplications(form, user_data, pdfbase64, id);
          }
          //---------------
          console.log("UPDATING USER DATA", user_data);
          await updateUser({
            ...user_data,
            [`${form}_updated`]: true,
            [`${form}_updated_time`]: getCurrentDate(),
          });
        }
      }, {});
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [isLoading]);

  const processPdfApplications = async (form, user_data, pdfbase64, id) => {
    let formattedUserData;

    if (form === "kinderwait_intake") {
      formattedUserData = configurateKinderWaitData(user_data);
    }
    // else if (form === "champ") {
    //   formattedUserData = configurateChampForm(user_data);
    // }
    else if (form === "medicare_savings") {
      formattedUserData = configurateMedicareSavingsData(user_data);
    }

    formattedUserData = cleanObject(formattedUserData);

    console.log("cleanign object", formattedUserData);

    const more_info_str = formatObjectIntoStr(formattedUserData);
    console.log(more_info_str);

    const endpointMap = {
      kinderwait_intake: "http://alistermartin.pythonanywhere.com/process_kinder_wait_application",
      // champ: "http://alistermartin.pythonanywhere.com/champform",
      medicare_savings: "http://alistermartin.pythonanywhere.com/process_medicare_application",
    };

    const endpoint = endpointMap[form];

    //--------------SENDING TO API
    catcher(
      async () => {
        const res = await axios.post(endpoint, {
          id,
          data: pdfbase64,
          more_info_str,
        });
        console.log(res);
        alert(res?.data?.message);
      },
      {
        loading_msg: "Saving application and sending email, please wait.",
      }
    );
    //---------------

    return formattedUserData;
  };

  const loading = () => (isLoading ? <Loading height="40vh" msg="Site is loading..." /> : "");

  function userDataString(dataComplete) {
    const data = flattenJson(dataComplete);
  
    const config = {
      "Name": data?.name,
      "Phone": data?.phone,
      "Email": data?.email,
      "Address": data?.address,
      "Zip": data?.zip,
      "City": data?.city,
      "Language": "English",
    };
  
    return encodeURIComponent(JSON.stringify(cleanObject(config)));
  }
  

  const completeUrl = (formConfig) => {
    if (!formConfig) return "";

    let url = formConfig.url;
    if (formConfig.dataString) {
      const params = {
        // "ahd-fill-me": btoa(formConfig.dataString(user)), this is for the old version of the userscript
        "ahd-fill-me": btoa(userDataString(user)),
        "ahd-user-id": user?.id?.value ?? user?.id,
        "ahd-loc": loc,
      };

      url = appendQueryParams(url, params);
    }

    return url;
  };
  if (config[form]?.require_signature && !user.signature) {
    return (
      <p>
        <h2>Signature required</h2> Signature is required for this application. Please fill the signature first.
      </p>
    );
  }

  return (
    <div style={{ width: "100%", height: "80vh", position: "relative" }}>
      <Typography.Title level={5}>{form?.replace("_", " ").toUpperCase()}</Typography.Title>
      {config[form]?.desc_alert && <Alert type="warning" showIcon description={config[form]?.desc_alert} />}
      <p>Instructions: Please complete the application below using the instuctions in the website. The data will be linked to the current website afterwards.</p>
      {user[config[form]?.uploaded_key] && (
        <Alert
          type="success"
          style={{ color: "darkgreen" }}
          description={"Submission has been recieved for this application. If the application didn't submit correctly in the external application you can resubmit it and it will overwrite that data."}
          message={
            <>
              <FaCheck /> SUBMISSION RECIEVED
            </>
          }
        ></Alert>
      )}

      {config[form]?.cannot_be_iframed && (
        <Alert
          type="warning"
          message={<b style={{ fontSize: "30px" }}>Open Site Seperately</b>}
          description={
            <div style={{ fontSize: "20px" }}>
              This site <span style={{ color: "red" }}>cannot</span> work in an iframe please open it seperately.
              <a href={completeUrl(config[form])} target="_blank">
                <Button size="large" type="primary" className="flex">
                  Site Link <TiArrowRightOutline />
                </Button>
              </a>
              After saving the application, the responses will be saved automatically to our site.
            </div>
          }
        />
      )}
      <iframe
        id="urud"
        src={completeUrl(config[form])}
        style={{
          width: "100%",
          height: "100%",
          maxHeight: "100vh",
          border: "none",
          border: "2px solid rebeccapurple",
          marginTop: 20,
          display: isLoading ? "none" : "block",
          visibility: config[form]?.cannot_be_iframed && "hidden",
        }}
        onLoad={handleLoad}
      ></iframe>
      {loading()}
    </div>
  );
}
