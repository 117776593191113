import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Card, List, Typography, Button } from "antd";
import "./ScreenerForm.css";
import MainContext from "../../contexts/mainContext";
import { TiArrowRightOutline } from "react-icons/ti";

const { Text } = Typography;

const maQuestions = [
  { id: "massHealth", text: "Do you have MassHealth?" },
  { id: "childrenUnder5", text: "Do you have children under 5?" },
  { id: "childrenUnder19", text: "Do you have children under 19?" },
  { id: "socialSecurityNumber", text: "Do you have a SSN?" },
];

const txQuestions = [
  { id: "ssn", text: "Do you have an SSN?" },
  { id: "medicaid", text: "Do you have Medicaid?" },
  { id: "childrenUnder5", text: "Do you have children under 5 years old?" },
  { id: "childrenUnder18", text: "Do you have children under 18 years old?" },
  { id: "over65", text: "Are you over 65 years old?" },
];

const ScreenerForm = ({ user, handleUpdate }) => {
  const { loc } = useContext(MainContext);
  const [formState, setFormState] = useState({});
  const [results, setResults] = useState({});
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (loc === "ma") {
      setQuestions(maQuestions);
      setFormState({
        massHealth: false,
        childrenUnder5: false,
        childrenUnder19: false,
        socialSecurityNumber: false,
      });
    } else if (loc === "tx") {
      setQuestions(txQuestions);
      setFormState({
        ssn: false,
        medicaid: false,
        childrenUnder5: false,
        childrenUnder18: false,
        over65: false,
      });
    }
  }, [loc]);

  const handleToggle = (id) => {
    setFormState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const calculateResults = () => {
    const { massHealth, childrenUnder5, childrenUnder19, socialSecurityNumber, ssn, medicaid, childrenUnder18, over65 } = formState;
    let newResults = {
      wic: false,
      lifeline: false,
      eitc: false,
      getYourRefund: false,
      noMatch: false,
    };

    if (loc === "ma") {
      if (massHealth && childrenUnder19 && socialSecurityNumber) {
        newResults.tafdc = true;
      }
      if (massHealth && socialSecurityNumber) {
        newResults.lifeline = true;
      }
      if (massHealth && childrenUnder5) {
        newResults.wic = true;
      }
      if (massHealth && !socialSecurityNumber && !childrenUnder5 && !childrenUnder19) {
        newResults.noMatch = true;
      }
    }

    if (loc === "tx") {
      if (!ssn) {
        newResults.noMatch = "An SSN is required for all programs.";
      } else {
        if (childrenUnder5) {
          newResults.wic = true;
        }
        if (childrenUnder18) {
          newResults.tanf = true;
        }
        if (medicaid && over65) {
          newResults.msp = true;
        }
        if (medicaid || newResults.tanf || newResults.wic) {
          newResults.lifeline = true;
        }
        newResults.eitc = true;
        newResults.snap = true;
        if (!Object.values(newResults).some(value => value === true)) {
          newResults.noMatch = true;
        }
      }
    }

    setResults(newResults);
    const updatedResults = Object.fromEntries(Object.entries(newResults).map(([key, value]) => [`screener_result_${key}`, value]));
    const updatedStates = Object.fromEntries(Object.entries(formState).map(([key, value]) => [`screener_state_${key}`, value]));

    handleUpdate({ ...updatedStates, ...updatedResults }, false);
  };

  const resultItems = [
    results.msp && { title: "MSP", description: "Medicare Savings Program", color: "darkgreen" },
    results.snap && { title: "SNAP", description: "Food Stamps", color: "darkgreen" },
    results.tanf && { title: "TANF", description: "TANF Cash assistance", color: "darkgreen" },
    results.tafdc && { title: "TAFDC", description: "TAFDC, Up to $447/month", color: "darkgreen" },
    results.lifeline && {
      title: "Lifeline",
      description: (
        <b style={{ textAlign: "right" }}>
          <p style={{ textAlign: "right", lineHeight: "0px" }}>Up to $111/year</p>
          <small style={{ fontWeight: "400" }}>for internet/phone subsidies</small>
        </b>
      ),
      color: "darkgreen",
    },
    results.wic && { title: "WIC", description: "Women, Infant, Children, $50-80/month", color: "darkgreen" },
    results.eitc && { title: "EITC", description: "Earned Income Tax Credit Program", color: "darkgreen" },
    results.getYourRefund && { title: "Get Your Refund", description: "Tax assistance program", color: "darkgreen" },
    results.noMatch && { title: "No Match Found", description: results.noMatch === true ? "No specific programs match patient's eligibility." : results.noMatch, color: "#F44336" },
  ].filter(Boolean);

  return (
    <Card className="form-container">
      <div className="checkbox-container">
        {questions.map((question) => (
          <div key={question.id} className="checkbox-field">
            <Checkbox checked={formState[question.id]} onChange={() => handleToggle(question.id)} style={{ fontSize: "18px", fontWeight: "bold" }}>
              {question.text}
            </Checkbox>
          </div>
        ))}
      </div>
      <Button type="default" onClick={calculateResults} style={{ margin: "20px 0", width: "100%" }}>
        Calculate
      </Button>
      <div className="results-container" style={{ display: resultItems?.length ? "" : "none" }}>
        <List
          dataSource={resultItems}
          renderItem={(item) => (
            <List.Item className="result-item">
              <Text strong style={{ fontSize: "18px", color: item.color }}>
                {item.title}:
              </Text>
              <Text style={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>{item.description}</Text>
            </List.Item>
          )}
        />
      </div>
      <div>
        {resultItems?.length ? (
          <Button
            size="large"
            style={{ width: "100%" }}
            type="primary"
            onClick={() => {
              handleUpdate({ eligibilityScreenedOn: new Date().getTime() });
            }}
          >
            Continue to filling applications
          </Button>
        ) : (
          ""
        )}
      </div>
    </Card>
  );
};

export function ScreenerFormDisplay({ data }) {
  const results = Object.entries(data)
    .filter(([key, value]) => key.startsWith("screener_result_") && (value?.value === true || value === true))
    .map(([key]) => key.replace("screener_result_", ""));

  return (
    <div style={{ padding: "1rem", boarder:"1px solid black" }}>
      {results.length > 0 && (
        <>
          <strong>
            <>Eligible Programs:</>
          </strong>
          <ol style={{ textTransform: "uppercase" }}>
            {results.map((program) => (
              <li>
                <strong>{program}</strong>
              </li>
            ))}
          </ol>
          <>Note: This is based on screener that you've used.</>
        </>
      )}
    </div>
  );
}

export default ScreenerForm;
