import { Tabs } from "antd";

const { TabPane } = Tabs;

const tabStyles = {
  fontSize: "16px",
  color: "#1890ff", // Customize the tab text color
  padding: "10px 20px", // Add some padding
};

const activeTabStyles = {
  ...tabStyles,
  fontWeight: "500",
};

const TabsComponent = ({ tabMobileOptions, setActiveTab, activeTab, items }) => (
  <Tabs destroyInactiveTabPane {...tabMobileOptions} type="line" defaultActiveKey="1" onChange={setActiveTab} activeKey={activeTab}>
    {items.map((item) => (
      <TabPane tab={<span style={activeTab === item.key ? activeTabStyles : tabStyles}>{item.label}</span>} key={item.key}>
        <div>{item.content}</div>
      </TabPane>
    ))}
  </Tabs>
);

export default TabsComponent;
